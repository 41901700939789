import { Form, Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import {
  leaf1,
  leaf2,
} from "../../../../components/ImgExport";
import { getLanguageUI } from "../../../../Services/languageUI";
import React from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as accountHandheldApi from "../../../../api/apiOwner/accountHandheldApi";
interface ModalCreateHandheldProps {
  show: boolean;
  haId: number;
  handleClose: () => void;
  setShowLoading: (load: boolean) => void;
  handleSuccess: () => void;
}

const ModalCreateHandheld = ({
  show, haId,
  handleClose, setShowLoading, handleSuccess
}: ModalCreateHandheldProps) => {
  const languageUI = getLanguageUI().giftCard;
  const languageUI2 = getLanguageUI().handheldPOS;
  const owner_id = useSelector(userSlice.selectorUserId);
  const [nameHandheldPOS, setNameHandheldPOS] = useState("");
  const [hsn, setHsn] = useState<string>("");
  const [messageError, setMessageError] = useState<string>("");

  const submitAddAccount = async () => {
    if (nameHandheldPOS.trim() === '') {
      setMessageError(languageUI2.pleaseEnterHsnName)
      return
    }
    if (hsn.trim() === '') {
      setMessageError(languageUI2.pleaseEnterHsnKey)
      return
    }
    if (owner_id) {
      setShowLoading(true)
      const req = await accountHandheldApi.addHandheldDevice(owner_id, haId, nameHandheldPOS, hsn);
      if (Number(req?.status) === 1) {
        handleSuccess()
      } else {
        setShowLoading(false)
        setMessageError(req?.message ?? "Bad request")
      }
    }
  }
  useEffect(() => {
    if (show) {
      setNameHandheldPOS('')
      setHsn('')
      setMessageError('')
    }

  }, [show])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-import-inventory"
      >
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="px-48px">
              <p className="text-delete-modal-customer mb-0 text-center">
                {languageUI2.createHandheldPOS}
              </p>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">
                  {languageUI2.nameHandheldPOS}:
                </p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={languageUI2.nameHandheldPOS}
                    value={nameHandheldPOS}
                    onChange={(e) => setNameHandheldPOS(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">HSN:</p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={"Hsn"}
                    value={hsn}
                    onChange={(e) => setHsn(e.target.value)}
                  />
                </div>
              </div>
              {messageError && (
                <div className="text-center text-danger text-italic">
                  {messageError}
                </div>
              )}
              <div className="d-flex justify-content-center w-100 mt-6 gap-3">
                <div
                  className="btn-import-inventory-footer "
                  onClick={handleClose}
                >
                  {languageUI.back}
                </div>
                <div className="btn-import-inventory-footer border-12B064 text-white bg-12B064" onClick={submitAddAccount}>
                  {languageUI.save}
                </div>
              </div>
            </div>
            <img src={leaf1} alt="" className="position-leaf1-voucher" />
            <img src={leaf2} alt="" className="position-leaf2-voucher" />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalCreateHandheld;
