/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as userSlice from "../store/slices/userSlice";
import socket from "../utils/socket";
import ModalLoading from "../components/ModalLoading";
import "./Styles.css";
import { checksNotification, notifitionHeader } from "../components/ImgExport";
import { Popover } from "@mui/material";
import {
  CardPaymentNotification,
  DishesOrderNotification,
  SupportNotification,
} from "../components/SVGChangeColor/SVGChangeColor";
import ItemsNotification from "../components/ItemsNotification";
import * as notificationApi from "../api/apiOwner/notificationApi";
import {
  checkActiveAccount,
  gTimeZoneDefault,
} from "../api/apiOwner/AccountInfor";
import moment from "moment-timezone";
import { getLanguageUI } from "../Services/languageUI";
import CachedIcon from "@mui/icons-material/Cached";
import LogoutIcon from "@mui/icons-material/Logout";
import ModalOTPOutStaff from "../pages/webowner/components/ModalOTPOutStaff/ModalOTPOutStaff";
import * as staffJointSlice from "../store/slices/staffJointSlice";
import {
  DisplayData,
  gConfigDisplay,
} from "../api/apiOwner/SettingChangePassword";

export interface NotificationItem {
  id: number;
  text: string;
  subText: string;
  icon?: string;
  avatar?: string;
  bgColor?: string;
}

interface TopbarProps {
  hideLogo?: boolean;
  navCssClasses?: string;
  openLeftMenuCallBack?: () => void;
  topbarDark?: boolean;
  pageName?: string;
  showFullSideBar: boolean;
}

const Topbar = ({
  hideLogo,
  navCssClasses,
  pageName,
  openLeftMenuCallBack,
  topbarDark,
  showFullSideBar,
}: TopbarProps) => {
  const languageUI = getLanguageUI().notification;

  const navbarCssClasses: string = navCssClasses || "";
  const containerCssClasses: string = !hideLogo ? "container-fluid" : "";

  // const { layoutType, leftSideBarType } = useSelector((state: RootState) => ({
  // const { layoutType } = useSelector((state: RootState) => ({
  //     layoutType: state.Layout.layoutType,
  //     leftSideBarType: state.Layout.leftSideBarType,
  // }));

  /**
   * Toggle the leftmenu when having mobile screen
   */

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const staffName = useSelector(staffJointSlice.selectorStaffName);

  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalLogoutStaff, setShowModalLogoutStaff] =
    useState<boolean>(false);

  const [changeOptionNotification, setChangeOptionNotification] =
    useState<number>(1);
  const [numNotification, setNumNotification] = useState<number>(0);
  const [countSecond, setCountSecond] = useState<number>(0);
  const [listNotification, setListNotification] = useState<
    notificationApi.Notification[]
  >([]);
  const staffJointId = useSelector(staffJointSlice.selectorStaffId);
  const typeDisplay = useSelector(staffJointSlice.selectorTypeDisplay);
  const secondCount = useSelector(staffJointSlice.selectorCountSecond);
  const secondAllowed = useSelector(staffJointSlice.selectorSecondAllowed);
  const [incrementSecond, setIncrementSecond] = useState(0);
  const [infoDisplay, setInfoDisplay] = useState<DisplayData>();
  const [showModalStaff, setShowModalStaff] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("UserIdStore", UserIdStore);
  // console.log("role", role);
  // console.log("history", location.pathname.indexOf("/web-admin"));

  const dispatchUser = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    upNotificationtoSee();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const sidebarNavStaffItems = [
    {
      to: "/web-owner/tablepos",
      section: ["/web-owner/tablepos"],
      is_show: 1,
    },
    {
      to: "/web-owner/posmenu",
      section: ["/web-owner/posmenu"],
      is_show: 0,
    },

    {
      to: "/web-owner/clockinclockout",
      section: ["/web-owner/clockinclockout"],
      is_show: 1,
    },
    {
      to: "/web-owner/order",
      section: ["/web-owner/order"],
      is_show: 1,
    },

    {
      to: "/web-owner/reservation",
      section: ["/web-owner/reservation"],
      is_show: 1,
    },
    {
      to: "/kitchencounter",
      section: ["/kitchencounter"],
      is_show: 1,
    },
    {
      to: "/web-owner/history",
      section: ["/web-owner/history"],
      is_show: 1,
    },
    {
      to: "/web-owner/pickup",
      section: ["/web-owner/pickup"],
      is_show: 1,
    },
  ];
  const dismisModalLogoutStaff = () => {
    setShowModalLogoutStaff(false);
  };

  const checkNotificaton = useCallback(async () => {
    var pathName = window.location.pathname;
    // await socket.on("PushShowNotification" + UserIdStore, (data) => {
    if (pathName.search("/web-owner/") > -1) {
      countNotificationByOwner();
      gListNotificationByOwner();
    }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "PushShowNotification" + UserIdStore;

    socket.on(eventName, checkNotificaton);

    return () => {
      socket.off(eventName, checkNotificaton);
    };
  }, [UserIdStore]);

  const countNotificationByOwner = async () => {
    if (UserIdStore) {
      const req = await notificationApi.countNotification(UserIdStore);
      if (Number(req?.status) === 1) {
        setNumNotification(req?.data ? Number(req?.data) : 0);
      }
    }
  };
  const gListNotificationByOwner = async () => {
    if (UserIdStore) {
      const req = await notificationApi.gListNotification(UserIdStore);
      if (Number(req?.status) === 1) {
        setListNotification(req?.data ?? []);
      }
    }
  };
  const upNotificationtoSee = async () => {
    setNumNotification(0);
    if (UserIdStore) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const req = await notificationApi.upIsreadNotificationtoSee(UserIdStore);
    }
  };
  const MarkReadAll = async () => {
    setNumNotification(0);
    if (UserIdStore) {
      const req = await notificationApi.upAllIsreadtoseen(UserIdStore);
      if (Number(req?.status) === 1) {
        setListNotification(
          listNotification.map((item) => {
            return { ...item, is_read: "2" };
          })
        );
      }
    }
  };
  useEffect(() => {
    if (listNotification.length === 0) {
      countNotificationByOwner();
      gListNotificationByOwner();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // useEffect(() => {
  //   if (UserIdStore) {
  //     setShowLoading(false);
  //     GetInfoOwner();
  //     countNotificationByOwner()
  //     gListNotificationByOwner()

  //   }
  // }, []);
  const gTimeZoneDefaultByOwner = async (owner_id: number) => {
    const req = await gTimeZoneDefault(owner_id);
    if (Number(req?.status) === 1) {
      moment.tz.setDefault(req?.data?.time_value ?? "America/Los_Angeles");
    }
  };

  const CheckActiveAccount = async (owner_id: number) => {
    const req = await checkActiveAccount(owner_id);
    if (Number(req?.status) === 1 && Number(req?.data) === 1) {
      await dispatchUser(userSlice.setUserId(0));
      await dispatchUser(userSlice.setUserName(""));
      await dispatchUser(userSlice.clearUser());
      localStorage.setItem("is_ownerMenu", "0");

      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    }
  };
  useEffect(() => {
    if (UserIdStore) {
      setCountSecond(1);
      gTimeZoneDefaultByOwner(UserIdStore);
      CheckActiveAccount(UserIdStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countSecond === 60 * 5) {
      gListNotificationByOwner();
      setCountSecond(0);
    } else {
      setTimeout(() => {
        setCountSecond(countSecond + 1);
      }, 1000);
    }
  }, [countSecond]);

  const handleOTPStaffSuccess = (staff_id: number, staff_name: string) => {
    if (infoDisplay && infoDisplay?.type_display === 2) {
      dispatch(
        staffJointSlice.setStaffJoint({
          count_second: 1,
          type_display: 2,
          second_allowed:
            (infoDisplay?.minutes_display
              ? Number(infoDisplay?.minutes_display)
              : secondAllowed) * 60,
          staff_id: staff_id,
          staff_name: staff_name,
        })
      );
      setIncrementSecond(1);
    } else {
      setIncrementSecond(0);
      // dispatch(staffJointSlice.clearStaffJoint());
      dispatch(
        staffJointSlice.setStaffJoint({
          count_second: 1,
          type_display: infoDisplay?.type_display ? infoDisplay?.type_display : 1,
          second_allowed:
            (infoDisplay?.minutes_display
              ? Number(infoDisplay?.minutes_display)
              : secondAllowed) * 60,
          staff_id: staff_id,
          staff_name: staff_name,
        })
      );
    }
  };
  var pathName = window.location.pathname;
  const gConfigDisplaySetting = async () => {
    if (
      UserIdStore &&
      pathName.search("/web-owner/") > -1 &&
      sidebarNavStaffItems.some((it) => it.to === pathName)
    ) {
      const Infomation = await gConfigDisplay(UserIdStore);
      if (Number(Infomation?.status) === 1 && Infomation?.data) {
        setInfoDisplay(Infomation?.data);
        setShowModalStaff(true);
      }
    }
  };
  const checkShowModalStaff = () => {
    if (
      incrementSecond >= secondAllowed &&
      pathName.search("/web-owner/") > -1 &&
      sidebarNavStaffItems.some((it) => it.to === pathName)
    ) {
      dispatch(staffJointSlice.clearStaffJoint());
      setIncrementSecond(0);
      gConfigDisplaySetting();
    } else {
      setTimeout(() => {
        if (typeDisplay === 2) {
          dispatch(staffJointSlice.IncrementSecond(1));
          setIncrementSecond(incrementSecond + 1);
        }
      }, 1000);
    }
  };
  useEffect(() => {
    if (incrementSecond > 0) {
      checkShowModalStaff();
    }
  }, [incrementSecond]);

  const checktypeDisplay = () => {
    if (typeDisplay === 2) {
      setIncrementSecond(secondCount);
    }
  };
  //chạy khi staffJointId clear
  useEffect(() => {
    if (secondAllowed === 0 && secondCount === 0)
      gConfigDisplaySetting();
  }, [secondAllowed, secondCount]);
  useEffect(() => {
    if (!staffJointId) {
      gConfigDisplaySetting();
    } else {
      checktypeDisplay();
    }
  }, []);
  // const pathName = window.location.pathname
  return (
    <React.Fragment>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      {/* <ModalOTPOutStaff
        handleClose={dismisModalLogoutStaff}
        show={showModalLogoutStaff}
        UserIdStore={UserIdStore}
      /> */}
      <ModalOTPOutStaff
        handleClose={() => setShowModalStaff(false)}
        show={showModalStaff}
        UserIdStore={UserIdStore}
        handleSuccess={handleOTPStaffSuccess}
      />
      <div
        className={`navbar-custom ${navbarCssClasses} bg-white`}
        style={{ marginLeft: showFullSideBar ? `100px` : "190px" }}
      >
        <div
          className={`${containerCssClasses} h-100 d-flex align-items-center justify-content-between pe-2`}
        >
          {!hideLogo && (
            <div className="d-flex align-items-center px-4">
              <div className="d-flex">
                <a href="" className="mb-0 link-home me-1">
                  <p className="mb-0">{languageUI.home} /</p>
                </a>
                <p className="mb-0 text-home-child">{pageName}</p>
              </div>
            </div>
          )}
          <p
            className={`mb-0 text-home-child ${pathName === "/web-owner/reservation" ? "mr-300px " : ""
              }`}
          >
            {staffName}
          </p>
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn-reload-page"
              onClick={() => {
                dispatch(staffJointSlice.clearStaffJoint());
                gConfigDisplaySetting();
              }}
            >
              <LogoutIcon style={{ color: "#4a4a6a", marginRight: "4px" }} />
              {languageUI.exit}
            </button>

            <button
              className="btn-reload-page"
              onClick={() => window.location.reload()}
            >
              <CachedIcon style={{ color: "#4a4a6a", marginRight: "4px" }} />
              {languageUI.reloadPage}
            </button>
            <button
              className="position-relative bg-white border-none "
              aria-describedby={id}
              onClick={handleClick}
            >
              <img src={notifitionHeader} alt="" />
              <div className="box-number-notifition">
                {numNotification < 100 ? numNotification : 99}
              </div>
            </button>
            {/* <ul className="list-unstyled topnav-menu float-end mb-0 h-100">
              <li className="dropdown notification-list topbar-dropdown h-100">
                <ProfileDropdown
                  profilePic={profilePic}
                  menuItems={
                    role == 0 || role == 2 ? ProfileAdminMenus : ProfileOwnerMenus
                  }
                  username={nameOwner ?? ''}
                  userTitle={"Founder"}
                />
              </li>
            </ul> */}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className=""
          >
            <div className="box-notification">
              <div className="p-6">
                <div className="box-options-notification">
                  <div
                    className={`${changeOptionNotification === 1
                      ? "option-notification-2"
                      : "option-notification"
                      }`}
                    onClick={() => setChangeOptionNotification(1)}
                  >
                    <DishesOrderNotification
                      fill={
                        changeOptionNotification === 1 ? "#0FA54A" : "#4a4a6a"
                      }
                    />
                    <p
                      className={`mb-0 text-option-notification ${changeOptionNotification === 1
                        ? "text-0FA54A"
                        : "text-4a4a6a"
                        }`}
                    >
                      {languageUI.order}
                    </p>
                  </div>
                  <div
                    className={`${changeOptionNotification === 2
                      ? "option-notification-2"
                      : "option-notification"
                      }`}
                    onClick={() => setChangeOptionNotification(2)}
                  >
                    <CardPaymentNotification
                      fill={
                        changeOptionNotification === 2 ? "#0FA54A" : "#4a4a6a"
                      }
                    />
                    <p
                      className={`mb-0 text-option-notification ${changeOptionNotification === 2
                        ? "text-0FA54A"
                        : "text-4a4a6a"
                        }`}
                    >
                      {languageUI.payment}
                    </p>
                  </div>
                  <div
                    className={`${changeOptionNotification === 3
                      ? "option-notification-2"
                      : "option-notification"
                      }`}
                    onClick={() => setChangeOptionNotification(3)}
                  >
                    <SupportNotification
                      fill={
                        changeOptionNotification === 3 ? "#0FA54A" : "#4a4a6a"
                      }
                    />
                    <p
                      className={`mb-0 text-option-notification ${changeOptionNotification === 3
                        ? "text-0FA54A"
                        : "text-4a4a6a"
                        }`}
                    >
                      {languageUI.suport}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-100s">
                <p className="mb-0 text-center text-notification-delete-2day">
                  {languageUI.delete2Day}
                </p>
                <div className="px-6 py-3">
                  <div
                    className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 1 ? "" : "d-none"
                      }`}
                  >
                    {listNotification.map(
                      (item, i) =>
                        Number(item?.type) === 3 && (
                          <ItemsNotification
                            key={`order${i}`}
                            table_name={item?.table_name}
                            order_id={item?.order_id}
                            status="order"
                            body={item?.body}
                            tableId={item?.table_id}
                            floorId={item?.floor_id}
                            is_read={item?.is_read}
                            notificationId={item?.id}
                          />
                        )
                    )}
                  </div>
                  <div
                    className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 2 ? "" : "d-none"
                      }`}
                  >
                    {listNotification.map(
                      (item, i) =>
                        Number(item?.type) === 1 && (
                          <ItemsNotification
                            key={`order${i}`}
                            table_name={item?.table_name}
                            order_id={item?.order_id}
                            status="payment"
                            body={item?.body}
                            tableId={item?.table_id}
                            floorId={item?.floor_id}
                            is_read={item?.is_read}
                            notificationId={item?.id}
                          />
                        )
                    )}
                  </div>
                  <div
                    className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 3 ? "" : "d-none"
                      }`}
                  >
                    {listNotification.map(
                      (item, i) =>
                        Number(item?.type) === 2 && (
                          <ItemsNotification
                            key={`order${i}`}
                            table_name={item?.table_name}
                            order_id={item?.order_id}
                            status="support"
                            body={item?.body}
                            tableId={item?.table_id}
                            floorId={item?.floor_id}
                            is_read={item?.is_read}
                            notificationId={item?.id}
                          />
                        )
                    )}
                  </div>
                </div>
              </div>
              <div
                className="footter-box-notification position-relative"
                onClick={MarkReadAll}
              >
                <img src={checksNotification} alt="" />
                <p className="mb-0 text-footer-nofitication">
                  {languageUI.rarkReadAll}
                </p>
              </div>
            </div>
          </Popover>

          {/* <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
              <button
                className="button-menu-mobile waves-effect waves-light d-none d-lg-block"
                onClick={toggleLeftSidebarWidth}
              >
                <i className="fe-menu"></i>
              </button>
            </li>
            <li>
              <button
                className="button-menu-mobile open-left d-lg-none d-bolck waves-effect waves-light"
                onClick={handleLeftMenuCallBack}
              >
                <i className="fe-menu" />
              </button>
            </li>

            <li>
              <Link
                to="#"
                className={classNames("navbar-toggle nav-link", {
                  open: isopen,
                })}
                onClick={handleLeftMenuCallBack}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(Topbar);
