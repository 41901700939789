import { Modal } from "react-bootstrap";
import "./Styles.css";
import { handClick, leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import $ from "jquery";
import React from "react";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { isNumber } from "../../../../utils/format";
import { useNavigate } from "react-router-dom";

interface ModalOrderPickupProps {
  show: boolean;
  floorSelect?: string;
  handleClose: () => void;
}

const ModalOrderPickup = ({ show, floorSelect, handleClose }: ModalOrderPickupProps) => {
  const LanguageUI = getLanguageUI().posTable;
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [messErr, setMessErr] = useState<string>('')

  const goToMenu = () => {
    if (customerName === '') {
      setMessErr('Please enter customer name!')
      return;
    }
    if (phoneNumber === '') {
      setMessErr('Please enter customer phone!')
      return;
    }
    navigate("/web-owner/posmenu", {
      state: { floor_id: floorSelect, customerName, customerPhone: phoneNumber, isPickup: true },
    })
  }

  useEffect(() => {
    setCustomerName('')
    setPhoneNumber('')
    setMessErr('')
  }, [show])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className=" border-modal-16px modal-pick-up"
      >
        <Modal.Dialog className="w-100 p-0">
          <Modal.Body className="w-100 p-0">
            <div className="position-relative px-8 d-flex align-items-center flex-column z-10 w-100">
              <h2 className="font-bold text-4a4a6a">{LanguageUI.pickup}</h2>
              <div className="w-100">
                <p className="mb-1 text-4a4a6a font-bold">{LanguageUI.customerName}:</p>
                <input
                  type="text"
                  className="w-100 h-40px rounded-3 border-999999"
                  value={customerName}
                  onChange={e => setCustomerName(e.target.value)}
                />
                <p className="mb-1 text-4a4a6a font-bold mt-2">
                  {LanguageUI.customerphonenumber}:
                </p>
                <input
                  inputMode="numeric"
                  maxLength={10}
                  type="text"
                  className="w-100 h-40px rounded-3 border-999999"
                  value={phoneNumber}
                  onChange={e => {
                    if (!isNumber(e.target.value)) return; setPhoneNumber(e.target.value)
                  }}

                />
              </div>
              {messErr && (
                <div className="text-danger text-italic text-center">
                  {messErr}
                </div>
              )}
              <button className="w-200px h-40px border-none bg-primary text-white font-bold  rounded-3 mt-3" onClick={goToMenu}>
                {LanguageUI.submit}
              </button>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalOrderPickup;
